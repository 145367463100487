import { FC } from "react";

import { InputLabel, MenuItem, Stack } from "@mui/material";

import { setCurrency } from "../../helpers";
import { MuiSelect } from "../MuiSelect";
import { TextInput } from "../TextInput";
import classes from "./CurrencyInput.module.scss";

interface Currency {
  uuid: string;
  iso4217: string;
  name: string;
  type: string;
}

interface Props {
  name: string;
  currencies: Currency[];
  placeholder?: string;
  label?: string;
  error?: string;
  required?: boolean;
}

export const CurrencyInput: FC<Props> = ({
  placeholder,
  currencies,
  name,
  label,
  error,
  required,
}) => {
  return (
    <Stack sx={{ width: "100%" }}>
      {!!label && (
        <InputLabel sx={{ fontSize: "14px", mb: "5px" }}>
          {label} {required && <span className={classes.asterisk}>*</span>}
        </InputLabel>
      )}
      <Stack flexDirection="row" sx={{ width: "100%" }}>
        <MuiSelect name="currency" id="currency" className={classes.select} error={!!error}>
          {currencies?.map((currency) => (
            <MenuItem key={currency.uuid} value={currency.uuid}>
              {setCurrency(currency.iso4217)}
            </MenuItem>
          ))}
        </MuiSelect>
        <TextInput
          name={name}
          type="number"
          placeholder={placeholder}
          className={classes.input}
          wrapperClass={classes.fullWidth}
          errorMessage={error}
        />
      </Stack>
    </Stack>
  );
};
